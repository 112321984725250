@import '@mobiscroll/react/dist/css/mobiscroll.scss';
@import '~bootstrap/scss/bootstrap';
@import './_variables.scss';
@import './sidebar.scss';
@import './addressSearch.scss';
@import './contactList.scss';
@import './contactCard.scss';
@import './contracts.scss';

// Bootstrap Overrides
.modal-footer {
    border-top: none;
}
// End Bootstrap Overrides

body {
    margin: 0;
}

.mh-400 {
    min-height: 400px;
}

.contracts-calendar {
    height: 800px;
}

.app-login {
    margin-top: 40vh;
}

.app-wrapper {
    overflow: auto;
    background-color: rgb(234, 234, 234);

    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    width: 100vw;
}

.app-content-wrapper {
    width: 100%;
    overflow: auto;
}

.app-login-button {
    background-color: $mbsc-ios-accent;
    color: white;
}

.loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.3;
    z-index: 9999999;
}

.card.client-color-code {
    .card-header {
        background-color: rgb(0, 200, 0);
    }
    .card-body {
        background-color: rgba(0, 200, 0, 0.15);
    }
}

.card.performer-color-code {
    .card-header {
        background-color: orange;
    }
    .card-body {
        background-color: rgba(255, 166, 0, 0.15);
    }
}

.card.mixed-color-code {
    // https://cssgradient.io/
    .card-header {
        background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 200, 0, 1) 0%, rgba(255, 166, 0, 1) 100%);
    }
    .card-body {
        background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 200, 0, 0.15) 0%, rgba(255, 166, 0, 0.15) 100%);
    }
}
