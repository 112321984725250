.address-selection-popup {
    .mbsc-popup.mbsc-popup-center {
        min-width: 400px;
        max-height: none !important;
    }
}

.selectable-address {
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1pt $mbsc-ios-accent solid;

    &:hover {
        background-color: $mbsc-ios-accent;
        color: white;
        cursor: pointer;
    }
}
